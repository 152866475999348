import React, {useEffect} from 'react'
import ht from '../data/ht.json'
import { Link } from 'react-router-dom'
import M from "materialize-css/dist/js/materialize.min.js";

export default function HTBio() {
  useEffect(() => {
    const elem = document.querySelectorAll('.carousel');
    M.Carousel.init(elem, {
      shift: 10
    });
  },[])

  // {name: "Chaplain & Sister", id: 2},
  // {name: "Tro Ta", id: 3}

  const classify = [
    {name: "Our Board", id: "1"},
  ]

  return (
    <div>
      {
        ht && classify.map(function(index) {
          return <div className="container" key={index.id}>
            <h4>{index.name}</h4>
            <div className="carousel">
            {ht.map(function(person) {
              const board = index.id
              if (person.board === board) {
                return <div className="carousel-item" key={person.id}>
                  <Link to={'/ht/' + person.id}>
                    <h6>{person.prefix} {person.firstName} {person.lastName}</h6>
                    <p className="subheader">{person.role}</p>
                    <img src={person.imageUrl} className="responsive-img" alt=""/>
                  </Link>
                </div>
              } else {
                return null
              }
            })}
            </div>
          </div>
        })
      }
    </div>
  )
}
