import React from 'react'

const CollapsibleLink = (props) => {
  const { href, description } = props;

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">{description}</a>
  )
}

const CollapsibleHeader = (props) => {
  const { icon, title } = props;

  return (
    <div className="collapsible-header">
      <i className="material-icons">{icon}</i>
      {title}
    </div>
  )
}

const CollapsibleBody = (props) => {
  const { content, link } = props;

  return (
    <div className="collapsible-body">
      {content && <p>{content}</p>}
      {link && <CollapsibleLink {...link}/>}
    </div>
  )
}

const CollapsibleItem = (props, key) => {
  const { title, icon, content, link } = props;

  return (
    <li key={key}>
      <CollapsibleHeader title={title} icon={icon}/>
      <CollapsibleBody content={content} link={link}/>
    </li>
  )
}

export default CollapsibleItem;
