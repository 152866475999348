import React, {useEffect} from 'react'
import ht from '../../data/ht.json'
import { Link } from 'react-router-dom'
import M from "materialize-css/dist/js/materialize.min.js";

const HuynhTruong = (props) => {
  const {
    id,
    prefix,
    firstName,
    lastName,
    role,
    imageUrl
  } = props;

  return (
    <div className="carousel-item" key={id}>
      <Link to={'/ht/' + id}>
        <h6>{prefix} {firstName} {lastName}</h6>
        <p>{role}</p>
        <img src={imageUrl} className="responsive-img"
          alt={firstName + " " + lastName}
        />
      </Link>
    </div>
  );
};

const PeopleCarousel = (props) => {
  const { nganh } = props;
  const teachers = ht.filter(person => person.nganh.startsWith(nganh));

  useEffect(() => {
    var carousel = document.querySelectorAll('.carousel')
    M.Carousel.init(carousel, {});
  }, []);

  return (
    <>
      <h4>Youth Leaders</h4>
      {
        ht &&
        <div className="carousel">
          {teachers.map((value, index) => {
            return <HuynhTruong {...value} key={index}/>
          })}
        </div>
      }
    </>
  );
};

export default PeopleCarousel;
