import React from "react";

const Introduction = (props) => {
  const { header, summary } = props;

  return (
    <div className="resources-intro-text">
      <h1>{header}</h1>
      <p>{summary}</p>
    </div>
  );
};

export default Introduction;
