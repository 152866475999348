import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ResourcePage from "./pages/ResourcePage";
import resources from "./data/resources";

/**
 * Website component that contains all the routes.
 * @returns {React.JSX}
 */
const Website = () => (
  <div>
    <Header />
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/about" component={AboutPage} />
      {resources.map(({ path, data }, index) => (
        <Route
          exact
          path={"/resources/" + path}
          render={() => <ResourcePage {...data} />}
          key={index}
        />
      ))}
    </Switch>
    <Footer />
  </div>
);

export default Website;
