import React from "react";

const HomeIntro = () => {
  return (
    <div className="home-intro">
      <div className="home-intro-section">
        <h1 className="home-title">Đoàn Chúa Thánh Thần</h1>
        <h3 className="home-subtitle">
          Vietnamese Eucharistic Youth Movement Holy Spirit Chapter
        </h3>
      </div>
    </div>
  );
};

export default HomeIntro;
