import React, { useEffect } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import "../../assets/styles/resources.css";

const Picture = (props) => {
  const { src, alt } = props;

  return (
    <div className="col l5">
      <img src={src} alt={alt} className="responsive-img materialboxed" />
    </div>
  );
};

const Statement = (props) => {
  const { pic, title, message } = props;

  useEffect(() => {
    var materialbox = document.querySelectorAll(".materialboxed");
    M.Materialbox.init(materialbox, {});
  }, []);

  return (
    <div className="row valign-wrapper">
      <Picture {...pic} />
      <div className="col l7 left-align">
        <h4>{title}</h4>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Statement;
