import AuNhiData from "./resources/auNhi.json";
import BonMangData from "./resources/bonMang.json";
import CaDoanData from "./resources/caDoan.json";
import CampData from "./resources/camp.json";
import DtdpData from "./resources/dtdp.json";
import HiepSiData from "./resources/hiepSi.json";
import HuynhTruongData from "./resources/huynhTruong.json";
import NghiaSiData from "./resources/nghiaSi.json";
import PhuHuynhData from "./resources/phuHuynh.json";
import ThieuNhiData from "./resources/thieuNhi.json";
import TroTaData from "./resources/troTa.json";

const resources = [
  {
    path: "au-nhi",
    data: AuNhiData,
  },
  {
    path: "thieu-nhi",
    data: ThieuNhiData,
  },
  {
    path: "nghia-si",
    data: NghiaSiData,
  },
  {
    path: "hiep-si",
    data: HiepSiData,
  },
  {
    path: "dtdp",
    data: DtdpData,
  },
  {
    path: "huynh-truong",
    data: HuynhTruongData,
  },
  {
    path: "bon-mang",
    data: BonMangData,
  },
  {
    path: "ca-doan",
    data: CaDoanData,
  },
  {
    path: "camp",
    data: CampData,
  },
  {
    path: "phu-huynh",
    data: PhuHuynhData,
  },
  {
    path: "tro-ta",
    data: TroTaData,
  },
];

export default resources;
