import React, { createContext, useState } from 'react'

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
    const [isAuthenticated, setAuthStatus] = useState(false);
    const [user, setUser] = useState(null);

    return (
        <AuthContext.Provider value={{isAuthenticated, user, setAuthStatus, setUser}}>
            { props.children }
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;