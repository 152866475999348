import React from "react";
import { Link } from "react-router-dom";

import "../assets/styles/components.css";

const Button = (props) => {
  const { description, link } = props;

  // Very basic check.
  const isExternal = link[0] !== "/";

  return isExternal ? (
    <a className="btn" href={link} target="_blank" rel="noopener noreferrer">
      {description}
    </a>
  ) : (
    <Link className="btn" to={link}>
      {description}
    </Link>
  );
};

export default Button;
