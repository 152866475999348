import React, { useEffect } from "react";
import HomeEventPanel from "../components/home/HomeEventPanel.jsx";
import HomePanels from "../components/home/HomePanels.jsx";
import HomeResources from "../components/home/HomeResources.jsx";
import HomeIntro from "../components/home/HomeIntro.jsx";

import "../assets/styles/home.css";

/**
 * The Home Page of the website.
 * @returns home page component
 */
const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-page">
      <HomeIntro />
      <HomeEventPanel />
      <HomePanels />
      <HomeResources />
    </div>
  );
};

export default HomePage;
