import React, { useEffect } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import CollapsibleItem from "../CollapsibleItem.jsx";

const CourseMaterial = (props) => {
  const { material } = props;

  useEffect(() => {
    var collapsible = document.querySelectorAll(".collapsible");
    M.Collapsible.init(collapsible, {});
  }, []);

  return (
    <div>
      {material && (
        <div className="space">
          <h4>Resources</h4>
          <ul className="collapsible">
            {material.map((value, index) => {
              return <CollapsibleItem {...value} key={index} />;
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CourseMaterial;