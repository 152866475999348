import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import M from "materialize-css/dist/js/materialize.min.js";
import Logo from "../assets/pics/dctt-site-logo.png";
import "../assets/styles/header.css";
import { REGISTRATION_URL } from "../data/constants";

const dropdowns = [
  {
    description: "Events",
    id: "event-pages",
    resourcePages: ["Bon Mang", "Camp"],
  },
  {
    description: "Leadership",
    id: "dropdown-resources",
    resourcePages: ["Huynh Truong", "Phu Huynh", "Ca Doan", "divider"],
  },
  {
    description: "Nganhs",
    id: "nganh-resources",
    resourcePages: ["Au Nhi", "Thieu Nhi", "Nghia Si", "Hiep Si", "DTDP"],
  }
];

const Dropdown = ({ id, resourcePages, description, mobile }) => (
  <>
    <li>
      <a className="dropdown-trigger" href="#!" data-target={id + mobile}>
        {description}
        <i className="material-icons right">arrow_drop_down</i>
      </a>
    </li>
    <ul id={id + mobile} className="dropdown-content">
      {resourcePages.map((value, index) => {
        switch (value) {
          case "Events":
            return (
              <li key={index}>
                <Link to={"/" + value.toLowerCase()}>
                  {"Upcoming " + value}
                </Link>
              </li>
            );
          case "divider":
            return <li className="divider" key={index}></li>;
          default:
            const word = value.split(" ").join("-").toLowerCase();
            return (
              <li key={index}>
                <Link to={"/resources/" + word}>{value}</Link>
              </li>
            );
        }
      })}
    </ul>
  </>
);

/***
 * Registration button that redirects to the google form.
 * @returns {React.JSX} the button component.
 */
const RegistrationButton = () => (
  <li>
    <a className="waves-effect waves-light btn" href={REGISTRATION_URL} target="_blank" rel="noopener noreferrer">
      <i className="material-icons left">account_circle</i>
      Register
    </a>
  </li>
);

/**
 * Header that appears at the top of the page.
 * @returns {React.JSX} the header component
 */
const Header = () => {
  useEffect(() => {
    var dropdown = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(dropdown, { coverTrigger: false });
    var sidenav = document.querySelector(".sidenav");
    M.Sidenav.init(sidenav, {});
  }, []);

  return (
    <div>
      <nav className="nav">
        <div className="nav-wrapper container">
          <Link to="/" className="left">
            <img
              src={Logo}
              alt="brand-logo"
              className="brand-logo valign-wrapper"
            />
          </Link>
          <a href="#!" data-target="mobile-menu" className="sidenav-trigger">
            <i className="material-icons">menu</i>
          </a>

          {/** Menu shown in computer */}
          <ul className="right hide-on-med-and-down">
            <li>
              <Link className="about" to={"/about"}>About</Link>
            </li>
            {dropdowns.map((value, index) => (
              <Dropdown {...value} key={index} mobile="" />
            ))}
            <RegistrationButton />
          </ul>

          {/** Menu shown in mobile */}
          <ul className="sidenav" id="mobile-menu">
            <RegistrationButton />
            <li>
              <Link className="about" to={"/about"}>About</Link>
            </li>
            {dropdowns.map((value, index) => (
              <Dropdown {...value} key={index} mobile="-mobile" />
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
