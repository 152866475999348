import React, { useEffect } from "react";
import Introduction from "../components/resources/Introduction.jsx";
import Statement from "../components/resources/Statement.jsx";
import PeopleCarousel from "../components/resources/PeopleCarousel.jsx";
import CourseMaterial from "../components/resources/CourseMaterial.jsx";

import "../assets/styles/resources.css";

const ResourcePage = (props) => {
  const { intro, nganh, statement, material } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <Introduction {...intro} />
      <Statement {...statement} />
      {material && <CourseMaterial material={material} />}
      {nganh && (
        <div>
          <PeopleCarousel nganh={nganh} />
        </div>
      )}
    </div>
  );
};

export default ResourcePage;
