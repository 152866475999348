import React from "react";
import Button from "./Button";

import "../assets/styles/components.css";

const Card = (props) => {
  const { image, title, description, buttonTo, buttonText, horizontal, height } = props;

  return (
    <div className={`card ${horizontal && 'horizontal'} ${height && 'height-restricted'}`}>
      <div className="card-image">
        <img
          className="card-image-overlay"
          src={`https://tnttcttpictures.s3-us-west-2.amazonaws.com/src/pages/${image}.png`}
          alt={image}
        />
      </div>
      <div className="card-stacked">
        <div className="card-content">
          <h5>{title}</h5>
          {description}
        </div>
        <div className="card-action">
          <Button link={buttonTo} description={buttonText} />
        </div>
      </div>
    </div>
  );
};

export default Card;
