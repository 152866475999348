import React, { createContext, useState } from 'react'

export const LayoutTypeContext = createContext();

const LayoutTypeContextProvider = (props) => {
    const [isMobile, setIsMobile] = useState(false);

    return (
        <LayoutTypeContext.Provider value={{isMobile, setIsMobile}}>
            { props.children }
        </LayoutTypeContext.Provider>
    );
}

export default LayoutTypeContextProvider;