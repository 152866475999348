import React from "react";
import HomeResourceButton from "./HomeResourceButton";
import resources from '../../data/resources';

const PATHS = resources.map((resource) => resource.path);

/**
 * The Row of resources.
 * @param {*} props 
 * @returns resource row component
 */
const ResourceRow = ({position, data, color}) => {
  const prefix = position === "top" ? "nganh " : "";

  return (
    <>
      {data.map((value, index) => (
        <HomeResourceButton
          to={`/resources/${value}`}
          color={color}
          text={prefix + value.split('-').join(' ')}
          img={value}
          key={index}
        />
      ))}
      <br />
    </>
  );
};

/**
 * The Resources displayed in the home page.
 * @returns home resource component.
 */
const HomeResources = () => {
  const opacity = 0.7;
  const grey = `rgba(100, 100, 100, ${opacity})`;
  const red = `rgba(182, 77, 77, ${opacity})`;

  return (
    <section className="container section">
      <h1>Resources</h1>
      <div className="hex-grid">
        <ResourceRow
          position={"top"}
          data={PATHS.slice(0, 4)}
          color={grey}
        />
        <ResourceRow
          position="middle"
          data={PATHS.slice(4, 7)}
          color={red}
        />
        <ResourceRow position="bottom" data={PATHS.slice(7)} color={grey} />
      </div>
    </section>
  );
};

export default HomeResources;
