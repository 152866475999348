import React from "react";
import Card from "../Card";
import { REGISTRATION_URL, POPE_URL} from '../../data/constants';

const CARD_DATA = [
  {
    title: "School Year",
    description:
      "Registration is open to all Au Nhi, Thieu Nhi, Nghia Si, Hiep Si. For newcomers, we hope you join us for the upcoming year!",
    image: "HomePage/carousel-1",
    buttonTo: REGISTRATION_URL,
    buttonText: "Register Today",
  },
  {
    title: "Pope's Monthly Intention",
    description:
      "We pray that the planet’s resources will not be plundered, but shared in a just and respectful manner.",
    image: "HomePage/carousel-2",
    buttonTo: POPE_URL,
    buttonText: "Read More",
  },
];

/**
 * The Panels in the home page.
 * @returns home panels component
 */
const HomePanels = () => (
  <section className="section row">
    {CARD_DATA.map((value, index) => (
      <div className="col s12 m8 offset-m2 l6" key={index}>
        <Card {...value} height />
      </div>
    ))}
  </section>
);

export default HomePanels;
