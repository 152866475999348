import React, {useEffect} from 'react'
import HTBio from '../components/HTBio'
import M from "materialize-css/dist/js/materialize.min.js";

export default function AboutPage() {

  useEffect(() => {
    window.scrollTo(0, 0)
    var elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems, {});
  }, [])

  return (
      <div className="container">
        <div className="about-header">
          <h3>About Us</h3>
          <p>
            Đoàn Chúa Thánh Thần - Chapter of the Holy Spirit - is a youth group part
            of the Vietnamese Eucharistic Youth Movement (VEYM) that was formed in 2001.
            We are based in Everett, Washington and have been serving the Vietnamese community
            for the past 19 years. At the beginning of the chapter, there were only a handful
            of youth leaders and less than 50 students. Our Đoàn has since grown to teach and
            watch over 100 students and over 30 youth leaders that are dedicated in educating and
            training youths to become better individuals and better Christians.
          </p>
        </div>
        <ul className="collapsible">
          <li>
            <div className="collapsible-header">
              <i className="fas fa-cross"></i>
              Our Purpose
            </div>
            <div className="collapsible-body left-align"><span>
              <strong>The Eucharistic Youth Movement has two general purposes:</strong>
              <ul>
                <li>
                  1. To educate and to train youths in becoming a better person as
                  well as an excellent Christian.
                </li>
                <li>
                  2. To organize and to guide all youths in spreading the Good News of the Gospel
                  and to actively be involved in working for the good of society through
                  charitable services and helping others.
                </li>
              </ul>
            </span></div>
          </li>
          <li>
            <div className="collapsible-header">
              <i className="material-icons">foundation</i>
              Our Foundation
            </div>
            <div className="collapsible-body left-align"><span>
              <p>The Eucharistic Youth Movement utilizes Gods Word in the Scriptures and the teaching of the Catholic Church as the basis for educating and guiding the youth in its activities.</p>
            </span></div>
          </li>
          <li>
            <div className="collapsible-header">
              <i className="fas fa-lightbulb"></i>
              Our Ideal
            </div>
            <div className="collapsible-body left-align"><span>
              <p>
                The Eucharistic Youth Movement accepts the Eucharistic Jesus Christ as its focal point,
                as source of spiritual life, and as ideal for the life of its members.
              </p>
            </span></div>
          </li>
          <li>
            <div className="collapsible-header">
              <i className="fas fa-gavel"></i>
              Our Principles
            </div>
            <div className="collapsible-body left-align"><span>
              <ul>
                <li>
                  1. Live God’s Word and be united with the Eucharistic Christ through Prayer,
                  Communion, Sacrifice, and Apostolic Work under the guidance of the Holy Spirit,
                  particularly apostolic work by the youth: “The youth must do apostolic work
                  first and directly to the youth” (Decree Lay Apostolicity, no. 12)
                </li>
                <li>
                  2. Love and honor the Blessed Virgin Mary, Mother of the Savior, so that through Our
                  Mother, we receive Christ and take Christ into our lives in a perfect way.
                </li>
                <li>
                  3. Honor the Vietnamese Martyrs; follow their examples to become witnesses for the
                  Good News of Christ.
                </li>
                <li>
                  4. Love and obey the representative of Christ, the Pope, who is the leader of the
                  Eucharistic Youth, and at the same time pray and implement the monthly wishes
                  of the Holy Father.
                </li>
                <li>
                  5. Improve humanity; protect and develop Vietnamese traditions and culture.
                </li>
              </ul>
            </span></div>
          </li>
        </ul>
        <HTBio/>
      </div>
  )
}
