import React from 'react'
import Logo from '../assets/pics/dctt-logo.png'
import "../assets/styles/footer.css";

// Constants 
const EMAIL = 'thieunhictt@gmail.com';
const SINH_HOAT = 'Sundays at 12:00 PM';
const MASS = '3:00 PM';
const SOCIALS = [
  {
    href: "www.facebook.com/TNTTChuaThanhThan",
    icon: "facebook"
  },
  {
    href: "www.instagram.com/doanchuathanhthan",
    icon: "instagram"
  }
];

/**
 * Displays links to the church's socials.
 * @returns socials component
 */
const Socials = () => (
  <div className="social-media">
    {SOCIALS.map((value, index) => {
      const { href, icon } = value;
      return (
        <a href={"https://" + href} key={index} target="_blank" rel="noopener noreferrer">
          <i className={"fab fa-" + icon + " fa-2x"}></i>
        </a>
      );
    })}
  </div>
);

/**
 * Displays a link to the email.
 * @returns email component
 */
const Email = () => (
  <>
    Contact us at <u><a href={`mailto:${EMAIL}`}>{EMAIL}</a></u>.
  </>
);

/**
 * Displays sinh hoat, mass time, address, and email/socials (if screen is medium).
 * @returns right panel component
 */
const RightPanel = () => (
  <div className="col s12 m5 offset-m1 l4 left-align">
    <p>Sinh Hoat: {SINH_HOAT}</p>
    <p><strong>Mass: </strong>{MASS}</p>
    <p>Our Lady of Perpetual Help Parish</p>
    <p>2619 Cedar St. Everett, WA 98201</p>

    {/* Hidden if screen is large */}
    <div className="hide-on-med-and-up">
      <p>
        <Email email="thieunhictt@gmail.com" />
      </p>
      <Socials />
    </div>
  </div>
);

/**
 * Displays the logo, a description, and email/socials (all if screen is large).
 * @returns right panel component
 */
const LeftPanel = () => (
  <div className="col s8 offset-s2 m5 offset-m1 l4 offset-l2 left-align valign-wrapper hide-on-small-only" >
    {/* Hidden if screen is medium or small */}
    <img src={Logo} alt="Logo" className="justify-content col s6 offset-s3 m4 " />
    <div className="col s6 m8">
      <p>
        We are the Holy Spirit Chapter of the Vietnamese Eucharistic Youth Movement.&nbsp;
        <Email />
      </p>
      <Socials />
    </div>
  </div >
);

/**
 * The Footer displayed at the bottom.
 * @returns footer component
 */
const Footer = () => (
  <>
    <div className="time-info valign-wrapper">
      <div className="row">
        <LeftPanel />
        <RightPanel />
      </div>
    </div>
    <div className="copyright-bar">
      <p>&copy; 2021 chuathanhthan.org</p>
    </div>
  </>
);

export default Footer;
