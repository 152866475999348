import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom'
import LanguageContextProvider from './contexts/LanguageContext'
import LayoutContextProvider from './contexts/LayoutTypeContext'
import AuthContextProvider from './contexts/AuthContext';
import Website from './Website'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <AuthContextProvider>
        <LanguageContextProvider>
        <LayoutContextProvider>
          <Website />
        </LayoutContextProvider>
        </LanguageContextProvider>
        </AuthContextProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
