import React from "react";
import Card from "../Card";
import { BO_HOA_THIENG_URL } from '../../data/constants';

const CARD_DATA = [
  {
    title: "Bo Hoa Thieng",
    description:
      "October is dedicated to the most Holy Rosary. Let's journey together for the rest of this month and offer up our collective prayers! Submit this form for each day that you participate in prayer. The nganh that offers up the most prayers will win an ultimate nganh prize!",
    image: "HomePage/bon-mang-altar",
    buttonTo: BO_HOA_THIENG_URL,
    buttonText: "Bo Hoa Thieng Form",
    horizontal: true
  }
];

/**
 * The Panels in the home page.
 * @returns home panels component
 */
const HomeEventPanel = () => (
  <section className="section row">
    {CARD_DATA.map((value, index) => (
      <div className="col s12" key={index}>
        <Card {...value} height />
      </div>
    ))}
  </section>
);

export default HomeEventPanel;