import React from "react";
import { Link } from "react-router-dom";

import '../../assets/styles/home.css';

/**
 * Hexagonal Button that redirects to a Resource page.
 * @param {*} props 
 * @returns 
 */
const HomeResourceButton = ({ to, color, text, img}) => {
  const style = {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundImage: `
      linear-gradient(${color}, ${color}),
      url(https://tnttcttpictures.s3-us-west-2.amazonaws.com/src/pages/home/resources/${img}.jpeg)
    `,
    visibility: "visible",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
  };

  return (
    <div className="hex-button">
      <div className="hex">
        <div className="inner-hex">
          <div className="inner-hex" style={style}>
            <Link to={to}>
              <h4>{text}</h4>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeResourceButton;
